import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import './layout.scss'

const Layout = ({ children, tilNum, optClass }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            {
              name: 'description',
              content:
                'TIL (Today I Learned) is a series of posts of daily learnings by PhoeniXAbhisheK. Contains development, programming and related posts',
            },
            {
              name: 'keywords',
              content:
                'share, learning, development, programming, phoenix gyaan, phoenixgyaan, phoenix, gyaan',
            },
          ]}
        >
          <html lang="en" />
        </Helmet>

        <div className={`blog ${optClass || ''}`}>
          <span className="til-num">
            {/* TIL# */}
            #{tilNum}
          </span>
          {children}
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  tilNum: PropTypes.string,
}

export default Layout
